import React, { useState, useEffect, Fragment } from 'react'
import va from '@vercel/analytics'
import { useStore } from '@nanostores/react'
import { isCartOpen, cartData } from '@stores/cartStore.js'
import { createCart, addToCart } from '@api/shopify.js'
import { slugify, getUniqueListBy } from '@utils/utils.js'
import { handleRemoveItem } from '@components/Cart/cartUtility.jsx'

const GWP_QUERY_URL =
    'https://services.mybcapps.com/bc-sf-filter/search?shop=monicaandandyonline.myshopify.com&limit=70&collection_scope=278635937854'

const productData = await fetch(GWP_QUERY_URL).then((r) => r.json())

// console.log('productData in GWP', productData)

const products = productData?.products

const GWP = () => {
    const $cartData = useStore(cartData) || null
    const [data, setData] = useState({})
    const [freeProductAdded, setFreeProductAdded] = useState(false)

    console.log('cartData in GWP', $cartData)

    let freeProduct

    useEffect(() => {
        if ($cartData) {
            let $newCartData = cartData.get()
            // console.log('$cartData in Shipping Remainder', $cartData, $newCartData)

            const invalidProductTypes = [
                'Gift Cards',
                'Gift Card',
                'Specialty SKU',
                'Embroidery Option',
                'Embroidery',
                'Virtual Classes',
                'Classes + Events',
                'Nursery',
                'Cribs',
                'Classes',
                'Strollers',
            ]

            const lines = $newCartData?.lines || null

            let applicableLines
            let freeProductInCart = false
            let cartId = $cartData.cartId
            let id = null
            let quantity = null
            let hasEmbroidery = null
            let variantId = null

            // get applicable items subtotal
            if (lines && lines.length > 0) {
                applicableLines = lines.filter((line) => {
                    const item = line.node
                    const productType = item?.merchandise?.product?.productType
                    const vendor = item?.merchandise?.product?.vendor
                    if (
                        item?.merchandise?.product?.tags.includes('freeproduct')
                    ) {
                        freeProductInCart = true
                        id = item?.id
                        quantity = item?.quantity
                        hasEmbroidery = false
                        variantId = item?.merchandise?.id
                    }
                    return (
                        !invalidProductTypes.includes(productType) &&
                        vendor === 'Monica + Andy'
                    )
                })
            }
            // console.log(
            //     '------freeProductInCart: ',
            //     freeProductInCart,
            //     id,
            //     quantity,
            //     hasEmbroidery,
            //     variantId
            // )

            // console.log('applicableLines', applicableLines)

            let subTotal
            let subtotal
            let shippingRemainder

            if (applicableLines && applicableLines.length > 0) {
                subtotal = applicableLines.reduce(function (
                    previousValue,
                    currentValue
                ) {
                    // console.log('previousValue, currentValue', previousValue, currentValue, typeof previousValue, typeof currentValue)
                    return (
                        parseFloat(previousValue) +
                        parseFloat(currentValue.node.cost.totalAmount.amount)
                    )
                },
                0)
            }

            if (subtotal) {
                subTotal = parseFloat(subtotal).toFixed(2) || 0
                shippingRemainder = parseFloat(75 - subTotal).toFixed(2) || 0
                freeProduct = parseFloat(100 - subTotal).toFixed(2) || 0
                if (subtotal >= 75) {
                    freeProductInCart
                        ? setFreeProductAdded(true)
                        : setFreeProductAdded(false)
                } else {
                    freeProductInCart
                        ? handleRemoveItem(
                              cartId,
                              id,
                              quantity,
                              hasEmbroidery,
                              variantId
                          )
                        : null
                }
            }

            // console.log('subtotal, subTotal', subtotal, subTotal)

            setData({
                ...$newCartData,
                subTotal: subTotal,
                shippingRemainder: shippingRemainder,
                freeProduct: freeProduct,
            })
        }
    }, [$cartData])

    const handleAddToCart = async (e) => {
        console.log('e in handleAddToCart FREE', e)

        e.preventDefault()

        const variantId = e.target.dataset.variantId

        let cartId = $cartData.cartId || ''

        const product = `gid://shopify/ProductVariant/${variantId}`

        let lines = {
            quantity: 1,
            merchandiseId: product,
        }

        let quantity = 1

        if (!cartId || cartId === '') {
            let cartCreateData = await createCart()
            cartId = cartCreateData.data.cartId

            // console.log cartCreateData', cartCreateData)

            let cartObj = {
                ...cartData.get(),
                cartId: cartId,
                checkoutUrl: cartCreateData.data.checkoutUrl,
            }

            cartData.set(cartObj)

            addToCart(cartId, lines).then((addToCartData) => {
                // console.log addToCartData', addToCartData)
                let cartLines = addToCartData.body.cartLinesAdd.cart.lines.edges
                let cartCost = addToCartData.body.cartLinesAdd.cart.cost
                // console.log cartLines', cartLines)

                let cartObj = {
                    ...cartData.get(),
                    lines: cartLines,
                    cost: cartCost,
                }

                cartData.set(cartObj)

                isCartOpen.set('is_cart_open', true)

                dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
                dataLayer.push({
                    event: 'add_to_cart',
                    page: 'cart free',
                    ecommerce: {
                        currency: 'USD',
                        value: 0,
                        items: [
                            {
                                item_id: variantId,
                                item_name: product.title,

                                item_brand: 'Monica + Andy',
                                // item_category: currentPattern ? currentPattern : '',

                                item_variant: variantId,
                                price: 0,
                                quantity: 1,
                            },
                        ],
                    },
                })

                va.track('GWP Added To Cart', {
                    productName: product.title,
                    productVariant: variantId ? variantId : '',
                })

                setFreeProductAdded(true)
            })
        } else {
            // console.log cartId exists', cartId, cartData)

            addToCart(cartId, lines).then((addToCartData) => {
                // console.log data', addToCartData)

                let cartLines = addToCartData.body.cartLinesAdd.cart.lines.edges
                let cartCost = addToCartData.body.cartLinesAdd.cart.cost

                let cartObj = {
                    ...cartData.get(),
                    lines: cartLines,
                    cost: cartCost,
                }

                cartData.set(cartObj)

                isCartOpen.set('is_cart_open', true)

                va.track('GWP Added To Cart', {
                    productName: product.title,
                    productVariant: variantId ? variantId : '',
                })

                setFreeProductAdded(true)
            })
        }
    }

    // console.log('-----freeProductAdded', freeProductAdded)

    return (
        <>
            {data.subTotal && data.subTotal >= 75 && !freeProductAdded ? (
                <div className='gwp-outer p-4 bg-oat/30'>
                    <div className='gwp-inner'>
                        <h2 className='gwp-title text-center text-lg xl:text-xl mb-3'>
                            Choose your free gift!
                        </h2>
                        <div className='gwp-products grid grid-cols-2 gap-3'>
                            {products?.map((product) => {
                                // console.log('product in GWP', product)
                                const variant = product?.variants[0]
                                const title = product?.title?.split('_')[0]
                                const print = product?.title?.split('_')[1]
                                return (
                                    <div
                                        key={product.id}
                                        className='gwp-product flex flex-col items-center justify-center text-center'
                                    >
                                        <div className='gwp-product-image mb-2'>
                                            <img
                                                src={product.images_info[0].src}
                                                alt={product.title}
                                                className='gwp-image'
                                            />
                                        </div>
                                        <div className='gwp-product-title h-[32px] leading-4'>
                                            <span className='gwp-title text-xs'>
                                                {title}
                                            </span>
                                            <br />
                                            <span className='gwp-print text-xs '>
                                                {print}
                                            </span>
                                        </div>
                                        <div className='gwp-add-to-cart my-2'>
                                            <button
                                                data-variant-id={variant.id}
                                                className='gwp-add-to-cart-button button-atc !h-[32px] hover:bg-flint hover:text-white'
                                                onClick={(e) =>
                                                    handleAddToCart(e)
                                                }
                                            >
                                                Add to cart
                                            </button>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    )
}
export default GWP
